const Winner = ({winner}) => {

  return (
    <>
    <div className='position mb-2'>
      <input type='text' value={winner} disabled/>
    </div>
  </>
  )
}

export default Winner

